document.addEventListener('alpine:init', () => {
  window.Alpine.data('modal', ({ serviceArray, test }) => ({
    isModalOpen: false,
    serviceArray,
    activeId: null,
    test,

    openModal(id) {
      this.activeId = id;
      this.isModalOpen = true;
    },

    get currentService() {
      return this.serviceArray[this.activeId]
    }
  }))
}) 