export function getSiblings(elem) {
  var siblings = [];
  var sibling = elem.parentNode.firstChild;

  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== elem) {
      siblings.push(sibling);
    }
    sibling = sibling.nextSibling
  }
  return siblings;
}

export function getHeight(elm) {
  elm.forEach(element => {
    if (!!document.querySelector(element)) {
      let height = document.querySelector(element).offsetHeight;
      let noSpecialChars = element.replace(/[^a-zA-Z0-9 ]/g, '');
      document.querySelector(':root').style.setProperty('--' + noSpecialChars + 'Height', height + 'px');
    }
  });
}

export function getWidth(elm) {
  elm.forEach(element => {
    if (!!document.querySelector(element)) {
      let width = document.querySelector(element).offsetWidth;
      let noSpecialChars = element.replace(/[^a-zA-Z0-9 ]/g, '');
      document.querySelector(':root').style.setProperty('--' + noSpecialChars + 'Width', width + 'px');
    }
  });
}

export function teleportElement(selectorToBeMoved, selectorOnWhichToAppendChild) {
  let elementOnWhichToAppendChild = document.querySelector(selectorOnWhichToAppendChild);

  [].forEach.call(document.querySelectorAll(selectorToBeMoved), function (el) {
    elementOnWhichToAppendChild.appendChild(el);
  })
}