import "@styles/home.scss";

document.addEventListener(
  "DOMContentLoaded",
  () => {
    /* Auto Imports */
    const assetContext = require.context(".", true, /\.(asset)\.(.*?)$/);
    assetContext.keys().forEach(assetContext);
    const styleContext = require.context(".", true, /\.(style)\.(.*?)$/);
    styleContext.keys().forEach(styleContext);
    const runtimeContext = require.context(".", true, /\.(runtime)\.(.*?)$/);
    runtimeContext.keys().forEach(runtimeContext);
  },
  { once: true },
);
