import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
Alpine.plugin(collapse)
window.Alpine = Alpine
import modal from './modal.js'
import { getHeight, getWidth } from './utils.js'
Alpine.data('modal', modal)
window.Alpine.start()

window.addEventListener(
  "load",
  () => {
    transition();
    getHeight(['[breadcrumb]', '[single-port__tabs]', '[register__aside]', '[links]', '[calendar]', '[description__frame]', '[single-port__heading]']);
    getWidth(['[single-port__container]', '[annuaire__button]', '[contact__button]']);
  },
);

window.addEventListener(
  "resize",
  () => {
    getHeight(['[breadcrumb]', '[single-port__tabs]', '[register__aside]', '[links]', '[calendar]', '[description__frame]', '[single-port__heading]']);
    getWidth(['[single-port__container]', '[annuaire__button]', '[contact__button]']);
  },
);

function transition() {
  setTimeout(() => {
    const transition = document.querySelector("html");
    transition.classList.add("disable");
  }, 400)
}

function scrollToTop() {
  const scrollBtn = document.querySelector('[scrollToTop]');

  const btnVisibility = () => {
    if (window.scrollY > 400) {
      scrollBtn.style.opacity = 1;
    } else {
      scrollBtn.style.opacity = 0;
    }
  };

  document.addEventListener("scroll", () => {
    btnVisibility();
  });

  scrollBtn.addEventListener("click", () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  });
}

scrollToTop();

function setupAnchorsSmoothScroll() {
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        const targetId = this.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
            window.scrollTo({
                top: targetElement.offsetTop,
                behavior: 'smooth'
            });
        }
    });
});
}

setupAnchorsSmoothScroll();

function alertMessage() {
  const alertMessage = document.querySelector('[alert-message]');
  const alertMessageClose = document.querySelectorAll('[alert-message__close]');
  const readValue = sessionStorage['alertMessage'];

  if (!!alertMessage) {
    alertMessageClose.forEach((item) => {
      item.addEventListener("click", () => {
        sessionStorage['alertMessage'] = "active";
        alertMessage.classList.add("alert-message_hidden")
      });
    });
    if (readValue) {
      alertMessage.classList.add("alert-message_hidden")
    }
  }
}

alertMessage();